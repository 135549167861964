@import 'vinbacco_variables';

// Here you can add other styles
@font-face {
  font-family: 'Roboto Rg';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

body {
  box-sizing: border-box;
  font-family: 'Roboto Rg';
  width: 100vw;
  height: 100vh;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 700;
}

.d-xs-none {
  display: none;
}

#root {
  width: 100%;
  height: 100%;
}

.login-logo {
  display: block;
  margin: auto;
  margin-bottom: 2em;
  width: 100%;
  max-width: 280px;
  height: auto;
}

.spinner-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
  user-select: none;
}

.text-align-center {
  text-align: center;
}

.text-align-end {
  text-align: end;
}


.vinbacco {
  .wrapper {
    background-color: $wrapper-background !important;
  }
  .sidebar {
    .sidebar-brand {
      background-color: $main-600;
    }
    .sidebar-nav {
      background-color: $main;
    }
  }
  .list-title {
    margin-bottom: 20px;
  }
  .list-actions {
    text-align: right;
  }
  .pagination {
    margin: 0;
  }
  .icon-button {
    margin-right: 1em;
  }
  .table-no-results {
    width: 100%;
    height: auto;
    text-align: center;
    box-sizing: border-box;
    padding: 1.5em;
  }
  .gallery-item {
    display: block;
    box-sizing: border-box;
    position: relative;
    width: 150px;
    height: 130px;
    & > .gallery-item-delete {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #E01E51;
      border: none;
    }
    & > .gallery-item-image {
      width: 150px;
      height: 100px;
      object-fit: contain;
    }
    & > .gallery-item-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
    }
  }
  .div-gallery-drag-drop {
    height: 500px;
    overflow-y: auto;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    &.disabled {
      opacity: 0.5;
    }
  }

  .btn-success {
    background-color: #2CC889;
    color: #160836;
    &:hover {
      background-color: #62cfa4;
    }
  }

  .btn-danger {
    background-color: #E01E51;
    color: #ffffff;
    &:hover {
      background-color: #d85c7d;
    }
  }

  .color-white {
    color: #ffffff !important;
  }

  .alert-primary-light {
    background-color: #16083620 !important;
  }

  .div-image-preview-placeholder {
    display: inline-block;
    width: 200px;
    height: 200px;
    background-color: white;
    border: 1px solid darkgray;
    border-radius: 15px;
    overflow: hidden;
  }

  .div-image-preview {
    display: inline-block;
    width: 200px;
    height: 200px;
    object-fit: contain;
    background-color: white;
    border: none,
  }

  .div-gallery-preview {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: white;
    border: 1px solid darkgray;
    border-radius: 15px;
  }

  .table-placeholder {
    display: block;
    box-sizing: border-box;
    margin-bottom: 2em;
    height: auto;
    width: 100%;
    overflow-x: auto;
    & > .table-scrollable {
      min-width: 800px;
    }
  }
}

.checkbox-container-grid {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
  grid-auto-flow: column;
  overflow-y: auto;
  position: relative;
}

.checkbox-container-grid-line {
  position: absolute;
  top: 0%;
  height: 100%;
  width: 1px;
  background-color: #2c384a40;
}

.checkbox-container-grid-line.line-one {
  left: 30%;
}

.checkbox-container-grid-line.line-two {
  left: 65%;
}

.nav-pill-border {
  border: 1px solid #dedede !important;
  margin-right: 5px;
  margin-top: 5px;
}

@media screen and (max-width: 993px) {
  .vinbacco {
    .list-actions {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 6px;
    }
  }
}

@media screen and (min-width: 992px) {
  .vinbacco {
    .div-gallery-preview {
      height: 500px;
    }
    .list-actions {
      & > * {
          margin-bottom: 5px;
        // &:not(:last-child) {
          &:not(:first-child) {
          // margin-right: 15px;
          margin-left: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .vinbacco {
    .border-left-md {
      border-left: 1px solid #2c384a40;
    }
    .border-right-md {
      border-right: 1px solid #2c384a40;
    }
  }
}



@import './ui/time-input.scss';

/*

*/